import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Page, QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import AsyncButton from '../../../../../components/AsyncButton'
import Toolbar from '../../../../../components/Toolbar/Toolbar'
import useBankConnections, { BankConnectionsProvider } from '../../../../../data-hooks/useBankConnections'
import wrapWithProvider from '../../../../../utils/wrapWithProvider'
import CreateBankConnectionPopup from './CreateBankConnectionPopup'
import ReconnectCell from './ReconnectCell'

function BankConnections() {
	const [showCreatePopup, setShowCreatePopup] = useState(false)
	const [linkToken, setLinkToken] = useState('')
	const { getLinkToken, isLoading, bankConnections } = useBankConnections()

	const createHandler = async () => {
		const token = await getLinkToken()
		setLinkToken(token)
		setShowCreatePopup(true)
	}

	const headers: QuickTableHeaders = {
		name: { title: 'Name', width: 200 },
		bankAccounts: { title: 'Accounts', component: ({ cell }) => <>{`${cell.length} accounts`}</>, width: 110 },
		reconnect: { title: '', component: ReconnectCell },
	}

	return (
		<div className='BankConnections'>
			{linkToken && <CreateBankConnectionPopup
				visible={showCreatePopup}
				setVisible={setShowCreatePopup}
				linkToken={linkToken}
				key={`${linkToken}${showCreatePopup}`} //reset the popup's internal state any time 'linktoken' or 'visible' change
			/>}

			<Toolbar>
				<AsyncButton className="button hollow" onClick={createHandler}><FontAwesomeIcon icon={faPlus} /> Connect Bank</AsyncButton>
			</Toolbar>

			<Page loading={isLoading}>
				<QuickTable
					headers={headers}
					data={bankConnections}
					primaryKey={'_id'}
					hover
				/>
			</Page>
		</div>
	)
}

export default wrapWithProvider(BankConnections, BankConnectionsProvider)
