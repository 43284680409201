import React from 'react'
import { useEffect } from 'react'
import { usePlaidLink } from 'react-plaid-link'

interface Props {
	linkToken: string,
	setLinkToken(t: string): any,
	closeOnError?: boolean,
	onSuccess(token: string): any
}

function InternalPlaidConnector({ linkToken, setLinkToken, closeOnError, onSuccess }: Props) {
	const eventHandler = (eventName: string, metadata: any) => {
		if (closeOnError && eventName === 'OPEN' && metadata?.view_name === 'ERROR') {
			setLinkToken('')
		}
	}

	const { open, ready } = usePlaidLink({
		onExit: (err, metadata) => {
			err && console.log({ err, metadata }) //eslint-disable-line no-console
			!err && setLinkToken('')
		},
		onEvent: eventHandler,
		token: linkToken,
		onSuccess,
	})

	useEffect(() => {
		ready && open()
	}, [ready])

	return null
}

export default function PlaidConnector(props: Props) {
	if (!props.linkToken) {
		return null
	}

	return <InternalPlaidConnector {...props} key={props.linkToken} />
}
