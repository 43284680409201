import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { BankTransaction } from '../../../../../../../common/types/BankingTypes'
import AsyncButton from '../../../../../components/AsyncButton'

export default function AdjustCell({ row, adjust }: QuickTableCellProps) {
	const transaction = row as BankTransaction

	const handler = () => {
		if (transaction.adjustments.ignored) {
			return adjust(transaction._id, { ignored: false })
		}

		return adjust(transaction._id, { ignored: true })
	}

	const icon = transaction.adjustments.ignored ? faEye : faEyeSlash

	return (
		<span>
			<AsyncButton onClick={handler} className='plain neutral'>
				<FontAwesomeIcon icon={icon as any} />
			</AsyncButton>
		</span>
	)
}
