import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Page, QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import { BankTransaction, BankTransactionFilters } from '../../../../../../common/types/BankingTypes'
import CurrencyCell from '../../../../components/QuickTableCells/CurrencyCell'
import DateCell from '../../../../components/QuickTableCells/DateCell'
import useSpendingSummary, { SpendingSummaryProvider } from '../../../../data-hooks/useSpendingSummary'
import { formatCurrency } from '../../../../utils/formatUtils'
import AdjustCell from './BankTransactions/AdjustCell'
import './Spending.css'
import TransactionFilterBar from './TransactionFilterBar'

const rowStyler = (row: BankTransaction) => ({
	color: row.adjustments.ignored ? 'gray' : '',
	opacity: row.adjustments.ignored ? '35%' : '',
})

function InnerSummary({ startDate, endDate, setStartDate, setEndDate, filters, setFilters }: any) {
	const { isLoading, summary, adjust, refreshTransactions } = useSpendingSummary()

	const headers: QuickTableHeaders = {
		name: { title: 'Name', width: 300 },
		amount: { title: 'Amount', component: CurrencyCell, width: 200, headerStyle: { textAlign: 'center' } },
		merchantName: { title: 'Merchant', width: 200 },
		date: { title: 'Date', component: DateCell },
		adjust: { title: '', component: AdjustCell, props: { adjust } },
	}

	const breakDownHeaders: QuickTableHeaders = {
		name: { title: 'Category', width: 300 },
		amount: { title: 'Amount', width: 150, component: CurrencyCell },
	}

	return (
		<Page loading={isLoading}>
			<TransactionFilterBar
				filters={filters}
				setFilters={setFilters}
				startDate={startDate}
				endDate={endDate}
				setEndDate={setEndDate}
				setStartDate={setStartDate}
				onRefresh={refreshTransactions}
			/>

			<div className="flexY">
				<div className="card">
					<div className="title">
						Average Daily Spending
					</div>
					<div className="value">
						{formatCurrency(summary?.averageDailySpending as any)}
					</div>
				</div>

				<div className="card">
					<div className="title">
						Adjusted Daily Spending
					</div>
					<div className="value">
						{formatCurrency(summary?.adjustedAverageDailySpending as any)}
					</div>
				</div>

				<div className="card">
					<div className="title">
						Total Spent
					</div>
					<div className="value">
						{formatCurrency(summary?.totalSpent as any)}
					</div>
				</div>

				<div className="card">
					<div className="title">
						Adjusted Total Spent
					</div>
					<div className="value">
						{formatCurrency(summary?.adjustedTotalSpent as any)}
					</div>
				</div>
			</div>

			<div className="flex">
				<div className="half">
					<h2>Merchant Breakdown</h2>
					<QuickTable
						data={summary?.merchantBreakdown || []}
						primaryKey={'name'}
						headers={breakDownHeaders}
						hover
					/>
				</div>

				<div className="half">
					<h2>Category Breakdown</h2>
					<QuickTable
						data={summary?.categoryBreakdown || []}
						primaryKey={'name'}
						headers={breakDownHeaders}
						hover
					/>
				</div>
			</div>

			<h2>Purchases</h2>
			<QuickTable
				headers={headers}
				data={summary?.transactions || []}
				primaryKey={'_id'}
				hover
				rowStyle={rowStyler}
			/>
		</Page>
	)
}

const DEFAULT_START_DATE = dayjs().startOf('month').add(-1, 'month').toDate()
const DEFAULT_END_DATE = dayjs().endOf('month').add(-1, 'month').toDate()

export default function Summary() {
	const [startDate, setStartDate] = useState(DEFAULT_START_DATE)
	const [endDate, setEndDate] = useState(DEFAULT_END_DATE)
	const [filters, setFilters] = useState<BankTransactionFilters>({})

	return <SpendingSummaryProvider
		startDate={startDate}
		endDate={endDate}
		filters={filters}
	>
		<InnerSummary
			startDate={startDate}
			endDate={endDate}
			setEndDate={setEndDate}
			setStartDate={setStartDate}
			filters={filters}
			setFilters={setFilters}
		/>
	</SpendingSummaryProvider>
}