import React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { getAuth, User, sendEmailVerification, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { getCurrentAccount, updateAccount } from '../api/accounts'
import { Page } from 'react-ui-scaffold'
import type { CionAccount } from '../../../common/types/UserTypes'

const AuthContext = createContext<{
	user: User | null,
	isLoading: boolean,
	loginWithGoogle(): Promise<void>,
	logOut(): any
	account: CionAccount,
	error: any,
	verifyEmail(): Promise<void>
}>(null as any)

export function AuthProvider({ children }: any) {
	const [user, setUser] = useState<User | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const [account, setAccount] = useState<CionAccount>(null as any) // null as any, beacsue if you are signed out the rest of the app won't render anyway
	const [error, setError] = useState<any>()

	const loginWithGoogle = async () => {
		const provider = new GoogleAuthProvider()
		const googleRes = await signInWithPopup(getAuth(), provider)
		updateAccount({
			//@ts-ignore
			firstName: googleRes._tokenResponse.firstName,
			//@ts-ignore
			lastName: googleRes._tokenResponse.lastName,
		})
	}

	useEffect(() => {
		const auth = getAuth()
		setError(null)

		const unsubAuth = auth.onAuthStateChanged(async (newUser) => {
			if (newUser) {
				const acc = await getCurrentAccount().catch((res) => {
					setError(res)
					auth.signOut()
				})

				setAccount(acc || null as any)
			} else {
				setAccount(null as any)
			}

			setUser(newUser)
			setIsLoading(false)
		})
		return unsubAuth
	}, [])

	// Hook/Provoider files shouldn't be responsible for UI but we're making an exception
	if (isLoading) {
		return <Page loading />
	}

	const logOut = async () => {
		setUser(null)
		await getAuth().signOut()
	}

	const verifyEmail = async () => sendEmailVerification(user as User)

	return <AuthContext.Provider value={{
		user,
		isLoading,
		account,
		logOut,
		verifyEmail,
		error,
		loginWithGoogle,
	}}>
		{children}
	</AuthContext.Provider>
}

export default function useAuth() {
	const val = useContext(AuthContext)
	if (!val) {
		throw new Error('useAuth outside provider!')
	}
	return val
}
