import { faCaretDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { SelectBoxOption } from '../../../../../common/types/GenericTypes'
import PanelButton, { PanelButtonRef } from '../../PanelButton/PanelButton'

interface Props {
	options: SelectBoxOption[],
	filteredOptions?: SelectBoxOption[],
	value: any,
	onValueChange(v: SelectBoxOption): any,
	wide?: boolean,
	className?: string,
	error?: boolean,
	children?: any,
	onOpen?(): void,
	onClose?(): void,
	style?: CSSProperties,
	placeholder?: string
}

export default function SelectPanelView({
	options,
	filteredOptions,
	value,
	onValueChange,
	wide,
	className,
	error,
	children,
	onOpen,
	onClose,
	style,
	placeholder,
}: Props) {
	const displayOptions = filteredOptions || options
	const panelButtonRef = useRef<PanelButtonRef>(null)

	const items = <>
		{children}
		{displayOptions.map((opt, i) => {
			const label = opt.value === value ? <><FontAwesomeIcon icon={faCheck} /> {opt.label}</> : opt.label
			const color = opt.value === value ? 'var(--neutralAccentColor)' : ''

			return <button
				className="button plain wide dropdownButtonItem"
				onClick={() => {
					onValueChange(opt)
					panelButtonRef.current?.close()
				}}
				key={i}
				style={{
					color,
				}}
			>
				{label}
			</button>
		})}
	</>

	const [activeOption, setActiveOption] = useState(() => {
		return options.find(opt => opt.value === value)
	})

	useEffect(() => {
		const newActive = options.find(opt => opt.value === value)

		if (newActive) {
			setActiveOption(newActive)
		}
	}, [options, value])

	const wideClass = wide ? 'wide' : ''

	return <PanelButton
		ref={panelButtonRef}
		panelContent={items}
		className={`input SelectBox hollow ${className} ${wideClass} ${error && 'error'}`}
		widePanel
		panelClassName='selectBoxPanel'
		onClose={onClose}
		onOpen={onOpen}
		style={style}
	>
		{activeOption?.label || placeholder || ''} <span className="caret">
			<FontAwesomeIcon icon={faCaretDown} />
		</span>
	</PanelButton>
}
