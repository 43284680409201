import React from 'react'
import MainLayout from '../../../../Layouts/MainLayout/MainLayout'

export default function Dashboard() {
	return (
		<MainLayout>

		</MainLayout>
	)
}
