import dayjs from 'dayjs'
import React from 'react'
import { BankTransactionFilters } from '../../../../../../common/types/BankingTypes'
import { SelectBoxOption } from '../../../../../../common/types/GenericTypes'
import SelectSearchBox from '../../../../components/inputs/selects/SelectSearchBox'
import TextBox from '../../../../components/inputs/TextBox'
import Toolbar from '../../../../components/Toolbar/Toolbar'
import useMerchants, { MerchantsProvider } from '../../../../data-hooks/useMerchants'
import wrapWithProvider from '../../../../utils/wrapWithProvider'
import AsyncButton from '../../../../components/AsyncButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

interface Props {
	filters: BankTransactionFilters,
	setFilters(f: BankTransactionFilters): void,
	startDate: Date,
	endDate: Date,
	setStartDate(d: string): void,
	setEndDate(d: string): void,
	onRefresh(): any
}

function TransactionFilterBar({ startDate, endDate, setStartDate, setEndDate, filters, setFilters, onRefresh }: Props) {
	const { merchants } = useMerchants()

	const merchantHandler = (v: SelectBoxOption) => {
		setFilters({
			...filters,
			merchantNameSearchKey: v.value,
		})
	}

	return (
		<Toolbar>
			<AsyncButton className="button hollow" onClick={onRefresh}>
				<FontAwesomeIcon icon={faSync} /> Refresh
			</AsyncButton>

			<span className="gap"></span>

			<TextBox
				type={'date'}
				value={dayjs(startDate).format('YYYY-MM-DD')}
				onChange={e => setStartDate(e.target.value)}
			/>

			<span style={{ padding: '0 20px' }}>
				to
			</span>

			<TextBox
				type={'date'}
				value={dayjs(endDate).format('YYYY-MM-DD')}
				onChange={e => setEndDate(e.target.value)}
			/>

			<span className="gap"></span>

			<SelectSearchBox
				options={[
					{ label: 'all merchants', value: undefined },
					...merchants,
				]}
				value={filters.merchantNameSearchKey}
				onValueChange={merchantHandler}
				style={{
					minWidth: 200,
				}}
			/>
		</Toolbar>
	)
}

export default wrapWithProvider(TransactionFilterBar, MerchantsProvider)