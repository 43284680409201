import { BankConnectionSummary, CreateBankConnectionParams, PlaidBankAccount } from '../../../common/types/BankingTypes'
import beam from './beam'

export const createBankConnection = (params: CreateBankConnectionParams) => beam.post<BankConnectionSummary>(`${beam.variables.basePath}/bankConnections`, params)

export const createLinkToken = () => beam.get<string>(`${beam.variables.basePath}/linkToken`)

export const createUpdateLinkToken = (bankConnectionId: string) => beam.get<string>(`${beam.variables.basePath}/bankConnections/${bankConnectionId}/updateLinkToken`)

export const getBankConnections = () => beam.get<BankConnectionSummary[]>(`${beam.variables.basePath}/bankConnections`)

export const getPlaidAccountsForBankConnection = (bankConnectionId: string) => beam.get<PlaidBankAccount[]>(`${beam.variables.basePath}/bankConnections/${bankConnectionId}/plaidAccounts`)

export const addAccountsToBankConnection = (plaidAccountIds: string[], bankConnectionId: string) => beam.post(`${beam.variables.basePath}/bankConnections/${bankConnectionId}/bankAccounts`, { plaidAccountIds })