import { faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { createUpdateLinkToken } from '../../../../../api/bankConnections'
import AsyncButton from '../../../../../components/AsyncButton'
import PlaidConnector from '../../../../../components/PlaidConnector'

export default function ReconnectCell({ row }: QuickTableCellProps) {
	const [token, setToken] = useState('')

	const clickHandler = async () => {
		const token = await createUpdateLinkToken(row._id)
		setToken(token)
	}

	return (
		<span>
			<PlaidConnector
				key={token}
				linkToken={token}
				setLinkToken={setToken}
				closeOnError
				onSuccess={(tok) => setToken(tok)}
			/>

			<AsyncButton className="button plain" style={{ color: 'gray' }} onClick={clickHandler}>
				<FontAwesomeIcon icon={faSync} />
			</AsyncButton>
		</span>
	)
}
