import React from 'react'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router'
import { SPENDING_PATH } from '../../../../../../common/paths'
import TabHeaders from '../../../../components/TabHeaders/TabHeaders'
import MainLayout from '../../../../Layouts/MainLayout/MainLayout'
import BankConnections from './BankConnections/BankConnections'
import BankTransactions from './BankTransactions/BankTransactions'
import Summary from './Summary'
import './Spending.css'
import Chat from './Chat/Chat'

export default function Spending() {
	const match: any = useRouteMatch()
	const history = useHistory()

	return (
		<MainLayout className='Spending'>
			<div className='tabControls'>
				<TabHeaders
					secondary
					activeTabId={match.params.tabId}
					setActiveTabId={(id) => history.push(`${SPENDING_PATH}/${id}`)}
					tabs={[
						{ id: 'Chat', title: 'Chat' },
						{ id: 'Summary', title: 'Summary' },
						{ id: 'Transactions', title: 'Transactions' },
						{ id: 'BankConnections', title: 'Bank Connections' },
					]}
				/>
			</div>

			<Switch>
				<Route component={BankConnections} path={`${SPENDING_PATH}/BankConnections`} />
				<Route component={BankTransactions} path={`${SPENDING_PATH}/Transactions`} />
				<Route component={Summary} path={`${SPENDING_PATH}/Summary`} />
				<Route component={Chat} path={`${SPENDING_PATH}/Chat`} />

				<Route component={Summary} path={`${SPENDING_PATH}/`}>
					<Redirect to={`${SPENDING_PATH}/Chat`} />
				</Route>
			</Switch>
		</MainLayout>
	)
}
