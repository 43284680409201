import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Page } from 'react-ui-scaffold'
import useAuth from '../../data-hooks/useAuth'
import { DASHBOARD_PATH, MAIN_PATH, SPENDING_PATH } from '../../../../common/paths'
import Dashboard from './pages/Dashboard/Dashboard'
import Spending from './pages/Spending/Spending'

export default function Main() {
	const auth = useAuth()

	return (
		<Page loading={auth.isLoading}>
			<Switch>
				<Route path={DASHBOARD_PATH} component={Dashboard} />

				<Route path={`${SPENDING_PATH}/:tabId`} component={Spending} />
				<Route path={SPENDING_PATH} component={Spending} />

				<Route path={MAIN_PATH}>
					<Redirect to={SPENDING_PATH} />
				</Route>
			</Switch>
		</Page>
	)
}