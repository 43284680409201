import { getAuth } from 'firebase/auth'
import Beam from 'browser-beam'

async function tokenBuilder() {
	const auth = getAuth()
	const token = await auth.currentUser?.getIdToken().catch(() => '')
	return token || ''
}

const beam = new Beam({
	tokenBuilder: tokenBuilder,
	urlPrefix: process.env.REACT_APP_SERVER_URL,
	directOut: true,
	variables: {
		basePath: '/api/private',
	},
})

export default beam
