export function formatCurrency(amount: number) {
	let num = Math.round(amount) / 100

	if (isNaN(num)) {
		return ' - '
	}

	return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num)
}

interface QueryStringParams {
	[name: string]: string | any
}

export function formatQueryString(params?: QueryStringParams) {

	if (!params) {
		return ''
	}

	let q = '?'

	Object.entries(params).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			value.forEach(elm => {
				q = `${q}&${key}=${elm}`
			})
		} else if (value !== undefined) {
			q = `${q}&${key}=${value}`
		}
	})

	return q
}
