import React from 'react'
import { Page } from 'react-ui-scaffold'
import './MainLayout.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import DropdownButton from '../../components/DropdownMenu/DropdownButton'
import useAuth from '../../data-hooks/useAuth'

interface Props {
	children: any,
	className?: string,
	loading?: boolean
}

export default function MainLayout({ children, className }: Props) {
	const { logOut, user } = useAuth()

	return (
		<Page className={`MainLayout ${className}`}>
			{children}

			<span className="floatingButtonContainer">
				<DropdownButton className='neutral floatingButton' items={[
					{ label: user?.email },
					{ color: 'var(--negativeAccentColor)', label: 'Sign Out', onClick: logOut },
				]}>
					<FontAwesomeIcon icon={faUser} />
				</DropdownButton>
			</span>
		</Page>
	)
}
