import { faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Page, QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import { BankTransaction } from '../../../../../../../common/types/BankingTypes'
import AsyncButton from '../../../../../components/AsyncButton'
import CurrencyCell from '../../../../../components/QuickTableCells/CurrencyCell'
import DateCell from '../../../../../components/QuickTableCells/DateCell'
import Toolbar from '../../../../../components/Toolbar/Toolbar'
import useBankTransactions, { BankTransactionsProvider } from '../../../../../data-hooks/useBankTransactions'
import wrapWithProvider from '../../../../../utils/wrapWithProvider'
import AdjustCell from './AdjustCell'

const rowStyler = (row: BankTransaction) => ({
	color: row.adjustments.ignored ? 'gray' : '',
	opacity: row.adjustments.ignored ? '35%' : '',
})

function BankTransactions() {
	const { isLoading, bankTransactions, refresh, adjust } = useBankTransactions()


	const headers: QuickTableHeaders = {
		name: { title: 'Name', width: 300 },
		amount: { title: 'Amount', component: CurrencyCell, width: 200, headerStyle: { textAlign: 'center' } },
		merchantName: { title: 'Merchant', width: 200 },
		date: { title: 'Date', component: DateCell },
		adjust: { title: '', component: AdjustCell, props: { adjust } },
	}

	return (
		<div className='BankConnections'>
			<Toolbar>
				<AsyncButton className="button hollow" onClick={refresh}>
					<FontAwesomeIcon icon={faSync} /> Refresh
				</AsyncButton>
			</Toolbar>

			<Page loading={isLoading}>
				<QuickTable
					headers={headers}
					data={bankTransactions}
					primaryKey={'_id'}
					hover
					rowStyle={rowStyler}
				/>
			</Page>
		</div>
	)
}

export default wrapWithProvider(BankTransactions, BankTransactionsProvider)