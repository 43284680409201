import React from 'react'
import './Chat.css'
import AsyncButton from '../../../../../components/AsyncButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { TextBox } from 'react-ui-scaffold'

export default function Chat() {
	return (
		<div className='Chat'>
			<div className="chatColumn">
				<div className="chatBox">
					<TextBox type="text" className="input wide" />

					<AsyncButton className='wide'>
						<FontAwesomeIcon icon={faPaperPlane} />
					</AsyncButton>
				</div>

			</div>
		</div>
	)
}
