import React from 'react'
import './Toolbar.css'

interface Props {
	children: any
}

export default function Toolbar({ children }: Props) {
	return (
		<div className='Toolbar flexY'>
			{children}
		</div>
	)
}
