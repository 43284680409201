import React, { createContext, useContext, useEffect, useState } from 'react'
import { BankTransactionAdjustments, BankTransactionFilters } from '../../../common/types/BankingTypes'
import { SpendingSummary } from '../../../common/types/SummaryTypes'
import { adjustBankTransaction, getBankTransactions } from '../api/bankTransactions'
import { getSpendingSummary } from '../api/summary'

const Context = createContext<{
	isLoading: boolean,
	summary?: SpendingSummary,
	adjust(id: string, p: BankTransactionAdjustments): Promise<void>,
	refreshTransactions(): Promise<void>
}>(null as any)

interface ProviderProps {
	children: any,
	startDate: Date,
	endDate: Date,
	filters: BankTransactionFilters,
}

export const SpendingSummaryProvider = ({ children, startDate, endDate, filters }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [summary, setSummary] = useState<SpendingSummary>()

	useEffect(() => {
		getSpendingSummary(startDate, endDate, filters)
			.then(setSummary)
			.finally(() => setIsLoading(false))
	}, [startDate, endDate, filters])

	const adjust = async (id: string, p: BankTransactionAdjustments) => {
		await adjustBankTransaction(id, p)
		getSpendingSummary(startDate, endDate, filters).then(setSummary)
	}

	const refreshTransactions = async () => {
		await getBankTransactions()
		await getSpendingSummary(startDate, endDate, filters).then(setSummary)
	}

	return <Context.Provider value={{
		isLoading,
		summary,
		adjust,
		refreshTransactions,
	}}>
		{children}
	</Context.Provider>
}

const useSpendingSummary = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSpendingSummary outside provider!')
	}
	return val
}

export default useSpendingSummary