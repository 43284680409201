import React, { useContext, createContext, useEffect, useState } from 'react'
import { BankTransaction, BankTransactionAdjustments } from '../../../common/types/BankingTypes'
import { adjustBankTransaction, getBankTransactions, syncBankTransactions } from '../api/bankTransactions'

const Context = createContext<{
	isLoading: boolean,
	bankTransactions: BankTransaction[],
	adjust(id:string, p: BankTransactionAdjustments): Promise<void>
	refresh(): Promise<void>,
}>(null as any)


interface ProviderProps {
	children: any,
}

export const BankTransactionsProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [bankTransactions, setBankTransactions] = useState<BankTransaction[]>([])

	useEffect(() => {
		getBankTransactions()
			.then(setBankTransactions)
			.finally(() => setIsLoading(false))
	}, [])

	const adjust = async (id:string, p: BankTransactionAdjustments) => {
		await adjustBankTransaction(id, p)
		await getBankTransactions().then(setBankTransactions)
	}

	const refresh = async () => {
		await syncBankTransactions()
		await getBankTransactions().then(setBankTransactions)
	}

	return <Context.Provider value={{
		isLoading,
		bankTransactions,
		adjust,
		refresh,
	}}>
		{children}
	</Context.Provider>
}

const useBankTransactions = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useBankTransactions outside provider!')
	}
	return val
}

export default useBankTransactions
