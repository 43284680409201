import React, { useContext, createContext, useEffect, useState } from 'react'
import { BankConnectionSummary, CreateBankConnectionParams } from '../../../common/types/BankingTypes'
import { addAccountsToBankConnection, createBankConnection, createLinkToken, createUpdateLinkToken, getBankConnections } from '../api/bankConnections'

const Context = createContext<{
	getLinkToken(): Promise<string>,
	getUpdateToken(bankConnectionId: string): Promise<string>,
	create(params: CreateBankConnectionParams): Promise<BankConnectionSummary>,
	bankConnections: BankConnectionSummary[],
	isLoading: boolean,
	addAccounts(bankConnectionId: string, plaidAccountIds: string[]): Promise<BankConnectionSummary>
}>(null as any)


interface ProviderProps {
	children: any
}

export const BankConnectionsProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [bankConnections, setBankConnections] = useState<BankConnectionSummary[]>([])

	const getLinkToken = () => createLinkToken()

	useEffect(() => {
		getBankConnections().then(data => {
			setBankConnections(data)
			setIsLoading(false)
		})
	}, [])

	const create = async (params: CreateBankConnectionParams) => {
		const newAccount = await createBankConnection(params)
		await getBankConnections().then(setBankConnections)
		return newAccount
	}

	const addAccounts = async (bankConnectionId: string, plaidAccountIds: string[]) => {
		const newConn = await addAccountsToBankConnection(plaidAccountIds, bankConnectionId)
		await getBankConnections().then(setBankConnections)
		return newConn
	}

	const getUpdateToken = async (bankConnectionId: string) => {
		const token = await createUpdateLinkToken(bankConnectionId)
		return token
	}


	return <Context.Provider value={{
		getLinkToken,
		getUpdateToken,
		isLoading,
		bankConnections,
		create,
		addAccounts,
	}}>
		{children}
	</Context.Provider>
}

const useBankConnections = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useBankConnections outside provider!')
	}
	return val
}

export default useBankConnections

