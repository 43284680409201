import React, { useState } from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Main from './pages/Main/Main'
import { AuthProvider } from './data-hooks/useAuth'
import { LOGIN_PATH, MAIN_PATH } from './../../common/paths'
import Login from './pages/Login/Login'
import './stylesheets/menuStyles.css'
import './stylesheets/popupStyles.css'
import PopupProvider from './components/Popup/PopupProvider'
import VerifiedRoute from './components/VerifiedRoute'
import { ScreenSizeProvider, useScreenSize } from './hooks/useScreenSize'
import wrapWithProvider from './utils/wrapWithProvider'
import { Page, PageMessageContents } from 'react-ui-scaffold'
import { ToastProvider } from './hooks/useToast'

function InnerApp() {
	const { screenSize } = useScreenSize()
	const [toast, setToast] = useState<PageMessageContents>()

	return (
		<PopupProvider className={screenSize}>
			<ToastProvider toast={toast} setToast={setToast}>
				<Page className={`App ${screenSize}`} message={toast}>
					<AuthProvider>
						<Router>
							<Switch>
								<Route path={LOGIN_PATH} component={Login} />
								<VerifiedRoute path={`${MAIN_PATH}/`} component={Main} />
							</Switch>
						</Router>
					</AuthProvider>
				</Page>
			</ToastProvider>
		</PopupProvider>
	)
}

export default wrapWithProvider(InnerApp, ScreenSizeProvider)
