import React, { useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { ControlledTabContainer, FreeButton, InfoTab } from 'react-ui-scaffold'
import { BankConnectionSummary } from '../../../../../../../common/types/BankingTypes'
import TextBox from '../../../../../components/inputs/TextBox'
import Popup from '../../../../../components/Popup/Popup'
import useAuth from '../../../../../data-hooks/useAuth'
import useBankConnections from '../../../../../data-hooks/useBankConnections'
import ConnectBankAccountsMenu from './ConnectBankAccountsMenu/ConnectBankAccountsMenu'

interface Props {
	visible: boolean,
	setVisible(v: boolean): void,
	linkToken: string,
}

export default function CreateBankConnectionPopup({ visible, setVisible, linkToken }: Props) {
	const [activeTab, setActiveTab] = useState('name')
	const [name, setName] = useState('')
	const [bankConnection, setBankConnection] = useState<BankConnectionSummary>()

	const { create } = useBankConnections()
	const { account } = useAuth()

	const [publicToken, setPublicToken] = useState('')

	//have to do it this way since usePlaidLink doesn't accept updates after it is initialized. This causes the 'name' state to be stale
	useEffect(() => {
		if (publicToken) {
			create({
				publicToken,
				name,
				userId: account._id,
			}).then((newConnection) => {
				setActiveTab('accounts')
				setPublicToken('')
				setBankConnection(newConnection)
			})
		}
	}, [publicToken])

	const { open, ready } = usePlaidLink({
		onExit: (err, metadata) => err && console.log({ err, metadata }), //eslint-disable-line no-console
		token: linkToken,
		onSuccess: (tok) => setPublicToken(tok),
	})

	return (
		<Popup visible={visible} setVisible={setVisible} title='Connect to your bank' className='CreateBankConnectionPopup'>
			<ControlledTabContainer activeTab={activeTab}>
				<InfoTab tabID='name'>
					<div className="story">
						Give your bank connection a name:
					</div>

					<div className="story">
						<TextBox
							type="text"
							className="input wide"
							placeholder='Bank Nickname'
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</div>
					<div className="story flexX">
						<FreeButton className="button" onClick={() => open()} loading={!ready} disabled={!name}>Next</FreeButton>
					</div>
				</InfoTab>

				<InfoTab tabID='accounts'>
					{bankConnection && (activeTab === 'accounts') && <ConnectBankAccountsMenu
						bankConnection={bankConnection}
						onClose={() => setVisible(false)}
					/>}
				</InfoTab>
			</ControlledTabContainer>
		</Popup>
	)
}
