import { BankTransactionFilters } from '../../../common/types/BankingTypes'
import { SpendingSummary } from '../../../common/types/SummaryTypes'
import { formatQueryString } from '../utils/formatUtils'
import beam from './beam'

export const getSpendingSummary = (
	startDate: Date,
	endDate: Date,
	filters: BankTransactionFilters
) => beam.post<SpendingSummary>(`${beam.variables.basePath}/summary/spending${formatQueryString(filters)}`, {
	startDate,
	endDate,
})