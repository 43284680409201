import React, { useContext, createContext, useEffect, useState } from 'react'
import { SelectBoxOption } from '../../../common/types/GenericTypes'
import { getMerchants } from '../api/bankTransactions'

const Context = createContext<{
	merchants: SelectBoxOption[],
	isLoading: boolean
}>(null as any)


interface ProviderProps {
	children: any
}

export const MerchantsProvider = ({ children }: ProviderProps) => {
	const [merchants, setMerchants] = useState<SelectBoxOption[]>([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		getMerchants()
			.then(setMerchants)
			.finally(() => setIsLoading(false))
	}, [])

	return <Context.Provider value={{
		merchants,
		isLoading,
	}}>
		{children}
	</Context.Provider>
}

const useMerchants = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useMerchants outside provider!')
	}
	return val
}

export default useMerchants