import { BankTransaction, BankTransactionAdjustments, BankTransactionFilters } from '../../../common/types/BankingTypes'
import { SelectBoxOption } from '../../../common/types/GenericTypes'
import { formatQueryString } from '../utils/formatUtils'
import beam from './beam'

export const adjustBankTransaction = (bankTransactionId: string, p: BankTransactionAdjustments) => beam.patch<BankTransaction>(`${beam.variables.basePath}/bankTransactions/${bankTransactionId}/adjustments`, { adjustments: p })

export const getBankTransactions = (filters?: BankTransactionFilters) => beam.get<BankTransaction[]>(`${beam.variables.basePath}/bankTransactions${formatQueryString(filters)}`)

export const syncBankTransactions = () => beam.post(`${beam.variables.basePath}/bankTransactions/sync`)

export const getMerchants = (searchText?: string) => beam.get<SelectBoxOption[]>(`${beam.variables.basePath}/bankTransactions/merchants${formatQueryString({ q: searchText })}`)